<template>
  <div class="contain">
    <div class="contain_left">
      <div class="contain1">
        <div style="display: flex; justify-content: space-between">
          <div class="contain1_title">
            <img
              v-if="docDetail.ext == '.doc' || docDetail.ext == '.docx'"
              src="@/assets/image/icon113.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="docDetail.ext == '.pdf' || docDetail.ext == '.pdfx'"
              src="@/assets/image/icon118.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="docDetail.ext == '.ppt'"
              src="@/assets/image/icon116.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="docDetail.ext == '.xls' || docDetail.ext == '.xlsx'"
              src="@/assets/image/icon114.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="docDetail.ext == '.txt'"
              src="@/assets/image/icon115.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else
              src="@/assets/image/icon117.png"
              alt=""
              class="contain1_title_img1"
            />
            <div>{{ docDetail.title }}</div>
            <!-- <img
              src="@/assets/image/icon57.png"
              alt=""
              class="contain1_title_img2"
            /> -->
          </div>
          <div style="display: flex; align-items: center">
            <div>￥&nbsp;{{ docDetail.price || 0 }}</div>
            <div
              style="
                width: 130px;
                height: 45px;
                background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
                border-radius: 4px;
                font-size: 15px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
                line-height: 45px;
                cursor: pointer;
                margin-left: 12px;
              "
              @click="docDownload()"
            >
              文档下载
            </div>
          </div>
        </div>
        <div class="contain1_tag">
          <div class="contain1_tag_left">
            <img src="@/assets/image/icon25.png" alt="" class="leftimg" />
            <div class="nav">
              <div
                class="nav_item"
                v-for="(item, index) in breadcrumb"
                :key="index"
                @click="goPage(item)"
              >
                <div>{{ item.title }}</div>
                <div v-if="breadcrumb.length - 1 != index">></div>
              </div>
            </div>
          </div>
          <div class="contain1_tag_right">
            <div class="contain1_tag_right_item">
              <img src="@/assets/image/icon103.png" alt="" />
              {{ docDetail.pages || 0 }}&nbsp;页
            </div>
            <div class="contain1_tag_right_item">
              <img src="@/assets/image/icon104.png" alt="" />
              {{ docDetail.download_count || 0 }}&nbsp;下载
            </div>
            <div class="contain1_tag_right_item">
              <img src="@/assets/image/icon105.png" alt="" />
              {{ docDetail.view_count || 0 }}&nbsp;浏览
            </div>
            <div class="contain1_tag_right_item">
              <img src="@/assets/image/icon106.png" alt="" />
              {{ docDetail.comment_count || 0 }}&nbsp;评论
            </div>
            <div class="contain1_tag_right_item">
              <img src="@/assets/image/icon107.png" alt="" />
              {{ docDetail.favorite_count || 0 }}&nbsp;收藏
            </div>
          </div>
        </div>
        <div class="contain1_line"></div>
        <!-- <el-descriptions
          :column="3"
          border
        >
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img
                  src="@/assets/image/icon104.png"
                  alt=""
                />
                上传
              </div>
            </template>
<template>
              <div class="info_style1">{{ docDetail.user.username }}</div>
            </template>
</el-descriptions-item>
<el-descriptions-item labelClassName="label1" contentClassName="contentClassName1">
  <template slot="label">
              <div class="label_title">
                <img
                  src="@/assets/image/icon85.png"
                  alt=""
                />
                格式
              </div>
            </template>
  <template>
              <div class="info_style1">
                <img
                  v-if="docDetail.ext == '.doc' || docDetail.ext == '.docx'"
                  src="@/assets/image/icon113.png"
                  alt=""
                />
                <img
                  v-else-if="docDetail.ext == '.pdf'"
                  src="@/assets/image/icon118.png"
                  alt=""
                />
                <img
                  v-else-if="docDetail.ext == '.ppt'"
                  src="@/assets/image/icon116.png"
                  alt=""
                />
                <img
                  v-else-if="docDetail.ext == '.xls'||docDetail.ext == '.xlsx'"
                  src="@/assets/image/icon114.png"
                  alt=""
                />
                <img
                  v-else-if="docDetail.ext == '.txt'"
                  src="@/assets/image/icon115.png"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/image/icon117.png"
                  alt=""
                />
                {{ docDetail.ext }}
              </div>
            </template>
</el-descriptions-item>
<el-descriptions-item labelClassName="label1" contentClassName="contentClassName1">
  <template slot="label">
              <div class="label_title">
                <img
                  src="@/assets/image/icon99.png"
                  alt=""
                />
                评分
              </div>
            </template>
  <template>
              <div class="info_style1">
                <el-rate
                  v-model="docDetail.score"
                  disabled
                  show-score
                  text-color="#EDB738"
                >
                </el-rate>
              </div>
            </template>
</el-descriptions-item>
</el-descriptions> -->
        <!-- <el-descriptions
          :column="1"
          border
        >
          <el-descriptions-item
            labelClassName="label2"
            contentClassName="contentClassName2"
          >
            <template slot="label">
              <div class="label_title">
                <img
                  src="@/assets/image/icon104.png"
                  alt=""
                />
                摘要
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ docDetail.description }}
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions> -->
        <div ref="docPages" class="doc-pages" @contextmenu.prevent>
          <el-skeleton animated v-if="!document.id">
            <template slot="template">
              <div style="background-color: #f6f6f6; padding: 5px">
                <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 520px"
                />
                <div style="height: 5px"></div>
                <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 520px"
                />
              </div>
            </template>
          </el-skeleton>
          <!-- <div  v-viewer>
            <div v-for="(page, index) in pages" :key="index + page.src">
              <el-image
                :src="page.src"
                :alt="page.alt"
                :data-source="page.lazySrc"
                lazy
                class="doc-page"
                :style="{
                  width: pageWidth + 'px',
                  height: pageHeight + 'px',
                }"
              >
              </el-image>
              <div
                class="doc-page"
                v-if="randomAdvertisement()"
                v-html="randomAdvertisement().content"
              ></div>
            </div>
          </div> -->
          <div>
            <div v-for="(page, index) in pages" :key="index + page.src">
              <el-image
                :src="$imgURL + page.src"
                :alt="page.alt"
                :data-source="page.lazySrc"
                lazy
                class="doc-page"
                :style="{
                  width: pageWidth + 'px',
                  height: pageHeight + 'px',
                }"
              >
              </el-image>
              <!-- <div
                class="doc-page"
                v-if="randomAdvertisement()"
                v-html="randomAdvertisement().content"
              ></div> -->
            </div>
          </div>
        </div>
        <!-- <div class="doc-page-more text-center">
          <div>下载文档到本地，方便使用</div>
          <el-button
            type="info"
            icon="el-icon-download"
            v-if="isGuestDownload"
            :loading="downloading"
            :size="isMobile ? 'medium' : ''"
            @click="guestDownload"
            >游客下载
          </el-button>
          <el-button
            type="warning"
            icon="el-icon-download"
            v-if="isVIPDownload"
            :loading="downloading"
            :size="isMobile ? 'medium' : ''"
            @click="vipDownload"
            >VIP下载
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-download"
            :size="isMobile ? 'medium' : ''"
            :loading="downloading"
            @click="downloadDocument"
          >
            {{ downloadText }}
            <template v-if="!(isGuestDownload || isVIPDownload)"
              >({{ formatBytes(document.size) }})</template
            >
          </el-button>
          <div v-if="document.preview - pages.length > 0">
            共 {{ document.pages }} 页， 还有
            {{ document.preview - pages.length }} 页可预览，
            <span class="el-link el-link--primary" @click="continueRead"
              >继续阅读</span
            >
          </div>
          <template v-else>
            <div v-if="document.pages != document.preview" class="text-muted">
              <small>
                - 可预览页数已用完，剩余
                {{ document.pages - document.preview }} 页请下载阅读 -
              </small>
            </div>
          </template>
        </div> -->
        <div class="doc_contain">
          <div class="doc_contain_surplus_num">
            <div class="doc_contain_surplus_num_title1">
              下载文档到本地，方便使用
            </div>
            <div
              style="display: flex; align-items: center"
              v-if="user.is_vip == 1"
            >
              <div class="doc_contain_surplus_num_title2" @click="download(1)">
                VIP下载
              </div>
            </div>
            <div style="display: flex; align-items: center" v-else>
              <div class="doc_contain_surplus_num_title2" @click="download(1)">
                VIP下载
              </div>
              <div style="width: 20px"></div>
              <div
                class="doc_contain_surplus_num_title2-2"
                @click="download(2)"
              >
                普通下载
              </div>
            </div>

            <div
              class="doc_contain_surplus_num_title3"
              v-if="document.preview - pages.length > 0"
            >
              <span
                >共 {{ document.pages }} 页，还有{{
  document.preview - pages_y
                }}页可预览，</span
              ><span @click="continueRead">继续阅读</span>
            </div>
            <div class="doc_contain_surplus_num_title3" v-else>
              <span
                >- 可预览页数已用完，剩余{{
                  Number(document.pages - document.preview)
                }}页请下载阅读 -</span
              >
            </div>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="actionButton">
          <div class="actionButton_left">
            <!-- <div class="actionButton_title">
              本文档由<span>{{ docDetail.user.username }}</span
              >于<span
                >{{ docDetail.created_at.split("T")[0] }}&nbsp;{{
                  docDetail.created_at.split("T")[1].split("Z")[0]
                }}</span
              >上传分享
            </div> -->
            <div class="actionButton_title">
              本文档由<span>{{ docDetail.user.username }}</span
              >于<span>{{ docDetail.created_at_str }}</span
              >上传分享
            </div>
            <div class="actionButton_btn1" @click="showReport()">
              <img src="@/assets/image/icon108.png" alt="" />
              举报
            </div>
          </div>
          <div class="actionButton_right" @click="isCollection">
            <img src="@/assets/image/icon109.png" alt="" />
            {{ is_favorite ? "已收藏" : "收藏" }}
          </div>
        </div>
      </div>
      <div class="commentContain">
        <div class="rate_contian">
          <span>评分</span
          ><el-rate
            v-model="myScore"
            :disabled="isScore"
            @change="changeScore"
          ></el-rate>
        </div>
        <div class="comment_contain_input">
          <el-input
            type="textarea"
            :autosize="{ minRows: 8 }"
            placeholder="请输入评论内容"
            v-model="content"
          >
          </el-input>
        </div>
        <div class="comment_contain_release">
          <div class="comment_contain_release_left">请文明评论，理性发言</div>
          <div class="comment_contain_release_right">
            <!-- <div class="comment_contain_release_right_title">
              点击获取验证码
            </div>
            <div class="comment_contain_release_right_input">
              <el-input v-model="text" placeholder="请输入验证码"></el-input>
            </div> -->
            <div
              class="comment_contain_release_right_btn"
              @click="postComments()"
            >
              <img src="@/assets/image/icon110.png" alt="" />发表点评
            </div>
          </div>
        </div>
        <div v-if="commonList.length > 0">
          <div v-for="(item, index) in commonList" :key="index">
            <div class="comment_contain_list">
              <div class="comment_contain_list_item">
                <img
                  :src="item.user.avatar ? $imgURL + item.user.avatar : icon52"
                  alt=""
                  class="comment_contain_list_item_img"
                />
                <div class="comment_contain_list_item_right">
                  <div class="comment_contain_list_item_right_title">
                    {{ item.user.username }}
                  </div>
                  <div class="comment_contain_list_item_right_content">
                    {{ item.content }}
                  </div>
                  <div class="comment_contain_list_item_right_btn">
                    <div class="comment_contain_list_item_right_btn_time">
                      <img src="@/assets/image/icon111.png" alt="" />{{
                        item.created_at
                      }}
                    </div>
                    <div
                      class="comment_contain_list_item_right_btn_reply"
                      @click="reply(item.id)"
                    >
                      <img src="@/assets/image/icon112.png" alt="" />回复
                    </div>
                  </div>
                  <div v-for="(i, sub) in item.children" :key="sub">
                    <div class="comment_contain_list">
                      <div class="comment_contain_list_item">
                        <img
                          :src="$imgURL + i.user.avatar"
                          alt=""
                          class="comment_contain_list_item_img"
                        />
                        <div class="comment_contain_list_item_right">
                          <div class="comment_contain_list_item_right_title">
                            {{ i.user.username }}
                          </div>
                          <div
                            class="comment_contain_list_item_right_content"
                            style="width: 868px"
                          >
                            <span>@{{ item.user.username }}</span
                            >&nbsp;{{ i.content }}
                          </div>
                          <div class="comment_contain_list_item_right_btn">
                            <div
                              class="comment_contain_list_item_right_btn_time"
                            >
                              <img src="@/assets/image/icon111.png" alt="" />{{
                                i.created_at
                              }}
                            </div>
                            <div
                              class="comment_contain_list_item_right_btn_reply"
                              @click="reply(item.id)"
                            >
                              <img
                                src="@/assets/image/icon112.png"
                                alt=""
                              />回复
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right_contain">
      <div class="contain2">
        <div class="contain2_title">分享用户</div>
        <div class="contain2_line"></div>
        <div class="contain2_img" @click="jumpUserDetail">
          <img
            :src="
              docDetail.user.avatar
                ? `${$imgURL + docDetail.user.avatar}`
                : icon52
            "
            alt=""
          />
        </div>
        <div class="contain2_name">{{ docDetail.user.username }}</div>
        <div class="contain2_num" v-if="isMy">
          <div class="contain2_num_item">
            <div>文档</div>
            <div>{{ docDetail.user.doc_count || 0 }}</div>
          </div>
          <div class="contain2_num_line"></div>
          <div class="contain2_num_item">
            <div>收藏</div>
            <div>{{ docDetail.user.favorite_me_count_document == undefined ? 0 : docDetail.user.favorite_me_count_document }}</div>
          </div>
          <div class="contain2_num_line"></div>
          <div class="contain2_num_item">
            <div>余额</div>
            <div>{{ docDetail.user.price == undefined ? 0:docDetail.user.price }}</div>
          </div>
        </div>
        <div class="contain2_desc">
          <div class="contain2_desc_title">个性签名</div>
          <div class="contain2_desc_desc">暂无个性签名</div>
        </div>
      </div>
      <div class="more_doc">
        <div class="moc_doc_title">
          <div>相关文档</div>
          <div @click="jumpSearch()">更多</div>
        </div>
        <div
          v-for="(item, index) in recommend_list"
          :key="index"
          class="moc_doc_list"
          @click="jumpDocDetail(item.id)"
        >
          <div class="moc_doc_list_img">
            <img
              v-if="item.ext == '.doc' || item.ext == '.docx'"
              src="@/assets/image/icon113.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="item.ext == '.pdf' || item.ext == '.pdfx'"
              src="@/assets/image/icon118.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="item.ext == '.ppt'"
              src="@/assets/image/icon116.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="item.ext == '.xls' || item.ext == '.xlsx'"
              src="@/assets/image/icon114.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else-if="item.ext == '.txt'"
              src="@/assets/image/icon115.png"
              alt=""
              class="contain1_title_img1"
            />
            <img
              v-else
              src="@/assets/image/icon117.png"
              alt=""
              class="contain1_title_img1"
            />
          </div>
          <div class="moc_doc_list_title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <el-dialog title="举报文档" :visible.sync="reportVisible" width="640px">
      <FormReport
        ref="reportForm"
        :init-report="report"
        :is-admin="false"
        @success="formReportSuccess"
      />
    </el-dialog>
  </div>
</template>

<script>
import { get, postJSON } from "/utils/request";
import { genPrevPage, getIcon } from "/utils/utils";
import FormReport from "../../components/FormReport.vue";
import loadingImage from "../../assets/image/loading.svg";
import { documentStatusOptions } from "/utils/enum";
import icon52 from "@/assets/image/icon52.png";

// import { postJSON } from "../../../utils/request";
export default {
  components: { FormReport },
  data() {
    return {
      originalTitle: document.title,
      pages_y:0,
      documentStatusOptions,
      breadcrumb: [{ id: "", title: "首页" }],
      rate: 4,
      commonList: [],
      doc_id: this.$route.query.id,
      uuid: this.$route.query.uuid,
      docDetail: {},
      token: "",
      category_id: [],
      is_favorite: false,
      reportVisible: false,
      report: {
        document_id: this.$route.query.id,
        document_title: "",
        reason: 1,
      },
      myScore: 0,
      isScore: false,
      parent_id: 0,
      content: "",
      document: {
        id: this.$route.query.id,
        score: 4.0,
        user: {
          id: 0,
        },
        attachment: {
          hash: "",
        },
      },
      pages: [],
      pagesPerRead: 10,
      pageHeight: 0,
      pageWidth: 0,
      currentPage: 1,
      currentPageFullscreen: 1,
      breadcrumbs: [],
      categoryMap: {},
      settings: {},
      loadingImage: loadingImage,
      tips: "",
      user: {},
      icon52: icon52,
      isMy: false,
      recommend_list: [], //推荐列表
    };
  },
  created() {
    this.getDocDetail();
    this.getCommentList();
    this.getCategory();
    this.getSettings();
    setTimeout(() => {
      this.getDocument();
    }, 600);
    if (window.localStorage.getItem("token")) {
      this.getFavorite(); //查询是否被收藏
      this.getDocScore(); //查询当前给文档的评分
      this.getUserInfo(); //个人信息
      setTimeout(() => {
        if (this.user.id == this.docDetail.user.id) return (this.isMy = true);
      }, 1000);
    }
  },
  watch: {
    $route(to, from) {
      console.log("to", to);
      console.log("from", from);
      let id = to.query.id;
      this.doc_id = id;
      this.report.document_id = id;
      this.getDocDetail();
      this.getCommentList();
      this.getCategory();
      this.getSettings();
      setTimeout(() => {
        this.getDocument();
      }, 600);
      if (window.localStorage.getItem("token")) {
        this.getFavorite(); //查询是否被收藏
        this.getDocScore(); //查询当前给文档的评分
        this.getUserInfo(); //个人信息
        setTimeout(() => {
          if (this.user.id == this.docDetail.user.id) return (this.isMy = true);
        }, 1000);
      }
    },
  },
  computed: {
    // isGuestDownload() {
    //   // 是否是游客下载
    //   return (
    //     this.document.id > 0 &&
    //     !this.document.is_vip &&
    //     !this.user.id &&
    //     !this.document.price &&
    //     this.settings.download.enable_guest_download
    //   );
    // },
    // downloadText() {
    //   return this.isGuestDownload || this.isVIPDownload
    //     ? "普通下载"
    //     : "下载文档";
    // },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.originalTitle = document.title;
      document.title = '郁金香文库';
    });
  },
  methods: {
    jumpSearch() {
      this.$router.push({
        name: "search",
        query: { keyword: this.docDetail.title },
      });
    },
    jumpDocDetail(id) {
      window.open(
        this.$router.resolve({ path: `/docDetail?id=${id}` }).href,
        "_blank"
      );

      // this.$router.push({ name: "docDetail", query: { id } });
    },
    goPage(item) {
      console.log(item);
      if (item.name == "首页") {
        this.$router.replace("/");
        return;
      }
      if (item.parent_id) {
        this.$router.push({
          name: "documentClassify",
          query: { id: item.id, parent_id: item.parent_id },
        });
      } else {
        this.$router.push({ name: "documentClassify", query: { id: item.id } });
      }
    },
    getDocDetail() {
      get("/document", {
        id: this.doc_id,
        uuid: this.uuid,
        with_author: true,
      }).then((res) => {
        // console.log("doc详情", res);
        document.title = res.title;
        this.docDetail = res;
        this.docDetail.score = res.score / 100;
        this.category_id = res.category_id;
        this.getCategoryList();
      });
      get("/document/related", {
        id: this.doc_id,
        // uuid: this.uuid,
        // with_author: true,
      }).then((res) => {
        console.log("根据id查推荐列表", res);
        this.recommend_list = res.document;
      });
    },
    getCategoryList() {
      get("/category/list", {}).then((res) => {
        console.log(res);
        let arr = [];
        res.category.map((item) => {
          if (this.category_id.includes(item.id)) {
            this.breadcrumb.push(item);
          }
        });
        console.log(arr);
      });
    },
    getFavorite() {
      // /api/v1/favorite
      get("/isFavorite", {
        document_id: this.doc_id,
      }).then((res) => {
        // console.log("是否收藏", res);
        this.is_favorite = res.data;
      });
    },
    getCommentList() {
      // console.log("document_id", this.id);
      get("/comment/list", {
        document_id: this.doc_id,
        order: "id asc",
      }).then((res) => {
        // console.log("聊天", res);
        if (Object.getOwnPropertyNames(res).length === 0) {
          this.commonList = [];
          return;
        }
        let once_comment = [];
        let two_comment = [];

        res.comment.map((item) => {
          // created_at
          if (item.created_at) {
            let date = new Date(item.created_at);
            date.setHours(date.getHours());
            item.created_at = date.toLocaleString();
            console.log(date.toLocaleString());
          }
          if (!item.parent_id) {
            let comment_obj = {
              ...item,
              children: [],
            };
            once_comment.push(comment_obj);
          } else {
            two_comment.push(item);
          }
        });
        once_comment.map((item) => {
          two_comment.map((item2) => {
            if (item.id == item2.parent_id) {
              item.children.push(item2);
            }
          });
        });
        this.commonList = once_comment;
        console.log("聊天列表返回！！！！！！", this.commonList);
      });
    },
    isCollection() {
      if (this.is_favorite) {
        // console.log("是否收藏", this.doc_id);
        // /api/v1/favorite
        postJSON(`/favorite/del`, { document_id: Number(this.doc_id) }).then(
          (res) => {
            // console.log("是否收藏", res);
            this.is_favorite = res.data;
          }
        );
      } else {
        postJSON("/favorite/add", {
          document_id: Number(this.doc_id),
        }).then((res) => {
          console.log("是否收藏", res);
          this.is_favorite = true;
        });
      }
      this.getDocDetail();
      this.getFavorite();
    },
    formReportSuccess() {
      this.reportVisible = false;
    },
    showReport() {
      console.log("this.docDetail", this.docDetail);
      this.report.document_id = this.docDetail.id;
      this.report.document_title = this.docDetail.title;
      this.reportVisible = true;
    },
    getDocScore() {
      get(`/document/score?document_id=${Number(this.doc_id)}`).then((res) => {
        if (Object.getOwnPropertyNames(res).length !== 0) {
          console.log("获取评分", res == {});
          this.isScore = true;
          this.myScore = res.score / 100 ?? 0;
        } else {
          this.isScore = false;
        }
      });
    },
    changeScore(e) {
      this.myScore = e;
      postJSON(`/document/score`, {
        document_id: Number(this.doc_id),
        score: Number(e * 100),
      }).then((res) => {
        console.log("评分返回", res.code);
        if (Object.getOwnPropertyNames(res).length !== 0) {
          this.isScore = true;
          this.getDocScore();
        }
      });
    },
    postComments() {
      postJSON(`/comment`, {
        document_id: Number(this.doc_id),
        parent_id: Number(this.parent_id),
        content: this.content,
        captcha: "",
        captcha_id: "",
      }).then((res) => {
        console.log("评论返回", res.code);
        if (Object.getOwnPropertyNames(res).length == 0) {
          this.getCommentList();
          this.parent_id = 0;
          this.content = "";
        }
      });
    },
    reply(id) {
      this.parent_id = id;
    },
    getCategory() {
      get("/category/list", {}).then((res) => {
        console.log("分类res", res);
        // this.categoryMap = categoryToTrees(res.category);
        const map = {};
        res.category.forEach((item) => {
          map[item.id] = item;
        });
        this.categoryMap = map;
      });
    },
    getDocument() {
      get("/document", {
        id: this.doc_id,
        uuid: this.uuid,
        with_author: true,
      }).then((res) => {
        console.log("doc详情", res);
        const doc = res || {};
        doc.score = parseFloat(doc.score) / 100 || 4.0;

        if (!doc.preview || doc.preview >= doc.pages) {
          doc.preview = doc.pages;
        }

        // 限定每次预览页数
        let preview = 2;
        if (doc.preview < preview) {
          preview = doc.preview;
        }

        // 限定预览页数，拼装图片链接
        const pages = [];
        for (let i = 1; i <= preview; i++) {
          const src = genPrevPage(
            doc.attachment.hash,
            i,
            doc.preview_ext,
            doc.enable_gzip
          );
          pages.push({
            lazySrc: src,
            src: src,
            alt: `${doc.title} 第${i + 1}页`,
          });
          console.log("--!!!I!!-----", i);

          console.log(
            "继续阅读11111",
            doc.attachment.hash,
            doc.preview_ext,
            doc.enable_gzip,
            i
          );
        }

        console.log("图片链接pages", JSON.stringify(pages));

        let breadcrumbs = [];
        let tmpBreadcrumbs = (doc.category_id || []).map((id) => {
          // console.log("---------------------id", id);
          let breadcrumb = this.categoryMap[id];
          if (!breadcrumb.parent_id) {
            breadcrumbs.push(breadcrumb);
          }
          return breadcrumb;
        });

        let length = tmpBreadcrumbs.length;
        for (let j = 0; j < length; j++) {
          for (let i = 0; i < tmpBreadcrumbs.length; i++) {
            let breadcrumb = tmpBreadcrumbs[i];
            if (
              breadcrumb.parent_id === breadcrumbs[breadcrumbs.length - 1].id
            ) {
              breadcrumbs.push(breadcrumb);
              tmpBreadcrumbs.splice(i, 1);
              break;
            }
          }
        }

        this.breadcrumbs = breadcrumbs;

        doc.icon = getIcon(doc.ext);
        this.pages = pages;
        this.document = doc;
        this.pageWidth = this.$refs.docPages.offsetWidth;
        this.pageHeight =
          (this.$refs.docPages.offsetWidth / doc.width) * doc.height;

        if (doc.status !== 2) {
          // 2 为文档已转换成功，不需要展示提示
          this.documentStatusOptions.map((item) => {
            if (item.value === doc.status) {
              this.tips = `当前文档【${item.label}】，可能暂时无法正常提供预览，建议您下载到本地进行阅读。`;
            }
          });
        }
      });
    },
    continueRead() {
      this.pages_y = 10;
      let pagesPerRead =
        this.settings.display.pages_per_read || this.pagesPerRead;
      let end = this.pages.length + pagesPerRead;
      if (end > this.document.preview) {
        end = this.document.preview;
      }
      let j = 0;
      let startLazyLoad = 2;
      if (document.fullscreenElement) startLazyLoad = 5;
      for (let i = this.pages.length + 1; i <= end; i++) {
        j += 1;
        const src = genPrevPage(
          this.document.attachment.hash,
          i,
          this.document.preview_ext,
          this.document.enable_gzip
        );
        this.pages.push({
          // 前两页，直接不要懒加载，如果非全屏
          src: j <= startLazyLoad ? src : src,
          lazySrc: src,
          alt: `${this.document.title} 第${i + 1}页`,
        });
      }
      // console.log("--!!!I!!-----", i);

      // console.log(
      //   "继续阅读11111",
      //   this.document.attachment.hash,
      //   this.document.preview_ext,
      //   this.document.enable_gzip
      // );
    },
    getSettings() {
      get("/settings").then((res) => {
        console.log("/settings", res);
        this.settings = res;
      });
    },
    jumpUserDetail() {},
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      console.log("res", res);
      this.user = res.data;
    },
    download(type) {
      console.log(type);
      // 1vip 2普通下载
      if (!window.localStorage.getItem("token")) {
        this.$message.error("请先登录");
        this.$router.replace("/");
        return;
      }
      if (type == 1) {
        if (this.user.is_vip != 1) {
          this.$message.error("您还不是VIP用户");
          this.$router.push("/buyVip");
          return;
        }
        this.getVipOrder();
      }
      if (type == 2) {
        this.getOrder();
      }
    },
    getVipOrder() {
      postJSON("/order", {
        order_type: 1,
        product_id: Number(this.doc_id),
      }).then((res) => {
        console.log("res", res);
        // status 1未购买  2已购买
        if (res.data.status == 1) {
          this.$router.push({
            name: "payment",
            query: {
              order_no: res.data.order_no, // res.order_no
            },
          });
        } else if (res.data.status == 2) {
          this.downloadFile();
        }
      });
    },
    getOrder() {
      postJSON("/order", {
        order_type: 1,
        product_id: Number(this.doc_id),
      }).then((res) => {
        console.log("res", res);
        // status 1未购买  2已购买
        if (res.data.status == 1) {
          this.$router.push({
            name: "payment",
            query: {
              order_no: res.data.order_no, // res.order_no
            },
          });
        } else if (res.data.status == 2) {
          this.downloadFile();
        }
      });
    },

    downloadFile() {
      get("/document/download", { id: Number(this.doc_id) }).then((res) => {
        console.log(res);
        window.location.href = `${this.$imgURL + res.url}`;
      });
    },
    downloadVIPFile() {
      //
      get("/document/download/vip", { id: Number(this.doc_id) }).then((res) => {
        window.location.href = `${this.$imgURL + res.url}`;
      });
    },
    docDownload() {
      if (!window.localStorage.getItem("token")) {
        this.$message.error("请先登录");
        this.$router.replace("/");
        return;
      }
      postJSON('/user/is_download',{ "d_id": Number(this.doc_id)}).then(res => {
        if(res.id_download) {
          // 提示
          this.$message.warning('您已下载过该文档，无需再次下载，请到浏览器下载历史中查看');
          return;
        } else {
          if (this.user.is_vip != 1) {
            // 非会员 普通下载
            this.getOrder();
          } else {
            // 会员下载
            this.getVipOrder();
          }
        }
      })
    },
    // randomAdvertisement() {
    //   let advertisements = this.advertisements.filter(
    //     (item) => item.position == "document_between"
    //   );
    //   if (advertisements.length > 0) {
    //     let index = Math.floor(Math.random() * advertisements.length);
    //     return advertisements[index];
    //   }
    //   return null;
    // },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
}

.contain_left {
  width: 1080px;
}

.contain1 {
  width: 1080px;
  // min-height: 2053px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;

  .contain1_title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #333333;
    letter-spacing: 1.2px;
    margin-bottom: 23px;

    .contain1_title_img1 {
      width: 28px;
      height: 28px;
      margin-right: 6px;
    }

    .contain1_title_img2 {
      width: 58px;
      height: 17px;
      margin-left: 6px;
    }
  }

  .contain1_tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;

    .contain1_tag_left {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .leftimg {
        width: 28px;
        height: 28px;
      }

      .nav {
        display: flex;
        align-items: center;

        .nav_item {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #333333;
          letter-spacing: 0.7px;
          cursor: pointer;

          div:nth-child(1) {
            margin: 0 6px;
          }
        }
      }
    }

    .contain1_tag_right {
      display: flex;
      align-items: center;

      .contain1_tag_right_item {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.6px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }
  }

  .contain1_line {
    height: 1px;
    background: #f4f4f4;
    margin-bottom: 21px;
  }

  .doc_contain {
    .doc_contain_surplus_num {
      height: 138px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 0px 0px 6px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .doc_contain_surplus_num_title1 {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.6px;
      }

      .doc_contain_surplus_num_title2 {
        width: 76px;
        height: 30px;
        margin: 17px 0;
        background: linear-gradient(
          124deg,
          #facc60 0%,
          #ffc43b 54%,
          #f49f00 91%
        );
        border-radius: 3px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }

      .doc_contain_surplus_num_title2-2 {
        width: 76px;
        height: 30px;
        margin: 17px 0;
        background: linear-gradient(
          124deg,
          #4cd0fc 0%,
          #3b9aff 54%,
          #0041f4 91%
        );
        border-radius: 3px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }

      .doc_contain_surplus_num_title3 {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        letter-spacing: 0.6px;

        span:nth-child(1) {
          color: #333333;
        }

        span:nth-child(2) {
          color: #23dc5a;
          cursor: pointer;
        }
      }
    }
  }

  .actionButton {
    display: flex;
    align-items: end;
    justify-content: space-between;

    .actionButton_left {
      cursor: pointer;

      .actionButton_title {
        color: #333333;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        letter-spacing: 0.7px;
        margin-bottom: 20px;

        span:nth-child(1) {
          color: #23dc5a;
        }

        span:nth-child(2) {
          color: #999999;
        }
      }

      .actionButton_btn1 {
        width: 119px;
        height: 44px;
        background: #edfff2;
        border: 1px solid #23dc5a;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #23dc5a;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }

    .actionButton_right {
      width: 119px;
      height: 44px;
      background: #33da61;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 0.7px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }
    }
  }
}

.commentContain {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 1080px;
  // height: 631px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;

  .rate_contian {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    span {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.7px;
      margin-right: 9px;
    }
  }

  .comment_contain_input {
    margin-bottom: 20px;
  }

  .comment_contain_release {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;

    .comment_contain_release_left {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      letter-spacing: 0.7px;
    }

    .comment_contain_release_right {
      display: flex;
      align-items: center;

      .comment_contain_release_right_title {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #333333;
        letter-spacing: 0.7px;
      }

      .comment_contain_release_right_input {
        width: 173px;
        height: 44px;
        margin: 0 20px 0 15px;
      }

      .comment_contain_release_right_btn {
        width: 148px;
        height: 44px;
        background: #33da61;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }
  }

  .comment_contain_list {
    .comment_contain_list_item {
      display: flex;

      .comment_contain_list_item_img {
        width: 68px;
        height: 68px;
        margin-right: 19px;
      }

      .comment_contain_list_item_right {
        .comment_contain_list_item_right_title {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #333333;
          letter-spacing: 0.8px;
          margin-bottom: 16px;
        }

        .comment_contain_list_item_right_content {
          width: 954px;
          min-height: 62px;
          background: #f6f7f8;
          border-radius: 6px;
          padding: 20px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #333333;
          letter-spacing: 0.8px;

          span {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #23dc5a;
            letter-spacing: 0.8px;
          }
        }

        .comment_contain_list_item_right_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;
          margin-bottom: 19px;

          .comment_contain_list_item_right_btn_time {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #999999;
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;

            img {
              width: 28px;
              height: 28px;
              margin-right: 6px;
            }
          }

          .comment_contain_list_item_right_btn_reply {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #23dc5a;
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 28px;
              height: 28px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}

.right_contain {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.contain2 {
  width: 300px;
  height: 399px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .contain2_title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
    margin-bottom: 19px;
  }

  .contain2_line {
    height: 1px;
    background: #f4f4f4;
  }

  .contain2_img {
    width: 68px;
    height: 68px;
    background: #f4f4f4;
    border-radius: 50%;
    margin: 20px auto;

    img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
    }
  }

  .contain2_name {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
    letter-spacing: 0.8px;
    margin-bottom: 43px;
  }

  .contain2_num {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .contain2_num_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      div:nth-child(1) {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.7px;
        margin-bottom: 6px;
      }

      div:nth-child(2) {
        font-size: 14px;
        font-family: DIN Alternate, DIN Alternate-Bold;
        font-weight: 700;
        color: #23dc5a;
        letter-spacing: 0.7px;
      }
    }

    .contain2_num_line {
      width: 1px;
      height: 21px;
      background: #efefef;
      margin: 0 29px;
    }
  }

  .contain2_desc {
    .contain2_desc_title {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.7px;
      margin-bottom: 17px;
    }

    .contain2_desc_desc {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.6px;
    }
  }
}

.more_doc {
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 20px;

  .moc_doc_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    div:nth-child(1) {
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      letter-spacing: 0.7px;
    }

    div:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: right;
      color: #999999;
      letter-spacing: 0.7px;
      cursor: pointer;
    }
  }

  .moc_doc_list {
    display: flex;
    align-items: center;
    padding: 15px 0 13px;
    border-top: 1px solid #f4f4f4;
    cursor: pointer;

    .moc_doc_list_img {
      width: 17px;
      height: 17px;
      margin-right: 4px;

      img {
        width: 17px;
        height: 17px;
      }
    }

    .moc_doc_list_title {
      width: 239px;
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      letter-spacing: 0.6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<style lang="scss">
.label1 {
  width: 287px;
  height: 85px;
}

.contentClassName1 {
  width: 394px;
}

.label_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.7px;
  margin-left: 20px;

  img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
  }
}

.label2 {
  width: 165px;
  height: 145px;
}

.contentClassName2 {
  width: 915px;
}

.info_style1 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.7px;
  margin-left: 20px;
  display: flex;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
  }
}

.doc-pages {
  min-height: 640px;

  .doc-page {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 5px solid #f6f6f6;
    border-bottom: 0;

    &:last-child {
      border-bottom: 5px solid #f6f6f6;
    }

    img {
      width: 100%;
      background-color: #fff;
    }

    .el-image__error {
      min-height: 360px;
    }
  }
}

.doc-page-more {
  padding: 30px 0;
  border: 5px solid #f6f6f6;
  border-top: 0;
  color: #565656;

  .el-button {
    margin: 10px 0;
  }
}
</style>
